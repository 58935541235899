.productID {
  margin: 0 50px 30px;
  height: 380px;
}

.productID_backArrow {
  width: 13px;
  height: 13px;
  margin: 0 10px;
}

.productID_products_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  color: rgb(80, 80, 80);
  fill: rgb(80, 80, 80);
  cursor: pointer;
  transition: color 0.3s;
}
.productID_products_line:hover {
  color: black;
  fill: black;
}

.productID_products_category {
  margin: 15px 15px 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.productID_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.productID_image {
  height: 300px;
  max-width: 38%;
  object-fit: contain;
}

.productID_data {
  margin: 10px 50px 10px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.productID_price {
  text-align: left;
  font-size: 20px;
  margin: 15px 0;
}

.productID_title {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
}

.productID_category {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
}

.productID_button {
  cursor: pointer;
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(87, 87, 87);
  background-color: rgb(209, 209, 209);
  transition: color 0.3s, background-color 0.3s;
}

.productID_button:hover {
  color: black;
  background-color: rgb(230, 230, 230);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid rgb(201, 201, 201);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(192, 192, 192) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
