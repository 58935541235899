.productImage {
  height: 350px;
  display: relative;
  width: 100%;
  margin: 0 5px 5px;
  box-shadow: 0px 0px 10px rgb(228, 228, 228);
  transition: box-shadow 0.3s;
}

.productImage:hover {
  box-shadow: 0px 0px 15px #a3a3a3;
}

.productImage_sale {
  color: #cfcfcf;
  margin: 0;
  padding: 0;
}

.productImage_rating {
  /* padding: 0; */
  /* margin: 0; */
  /* display: flex; */
  /* margin-top: -15px; */
}

.productImage_shop {
  margin-top: -10px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.productImage_info {
  height: 150px;
}
.productImage_link {
  text-decoration: none;
  cursor: pointer;
  color: white;
}

.productImage > button {
  /* background: #f0c14b;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 0;
    margin: 0; */
}

.productImage_price {
  padding: 0;
  margin-left: 3px;
  margin-right: 65px;
  color: white;
  font-weight: 300;
  font-size: 25px;
}

.productImage_title {
  margin: 0;
  padding: 0;
  color: white;
  font-weight: 300;
  font-size: 50px;
}

.productImage_absolute {
}

.productImage_description {
  margin: 0;
  margin-left: 1px;
  padding: 0;
  font-size: 20px;
  color: rgb(233, 233, 233);
}

.productImage_desc {
  margin-top: 5px;
  margin-bottom: -10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.productImage_info {
  margin: 135px 20px 0px;
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.productImage_button {
  padding: 15px 25px;
  border: 1px solid rgba(255, 255, 255, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.459);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.productImage_button:hover {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  background-color: rgba(138, 138, 138, 0.116);
}
