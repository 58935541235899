.checkout {
  display: flex;
  padding: 10px 60px 25px;
  margin: 0px 0px 15px;
  font-weight: 300;
  min-height: 600px;
  height: max-content;
}

/* .checkout_ad {
  width: 100%;
  margin-bottom: 10px;
  height: 100px;
  object-fit: cover;
} */

.checkout_title {
  margin-right: 5px;
  padding: 10px;
  margin-top: 50px;
  font-weight: 300;
  border-bottom: 1px solid lightgray;
}

.checkout_left {
  width: 100%;
  text-align: left;
}

.checkout_hello>h3 {
  font-weight: 300;
  font-size: 35px;
  margin: 20px 5px;
}