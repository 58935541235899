.checkoutProduct {
  display: flex;
  margin: 15px 0 15px;
  padding: 10px 10px;
  border: 1px solid rgb(214, 214, 214);
}

.checkoutProduct_info {
  padding-left: 20px;
  margin: 10px 0 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.checkoutProduct_info > button {
  padding: 10px 10px;
  margin-top: 25px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.checkoutProduct_info > button:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}

/* 
.checkoutProduct_info>button {
  background: #f0c14b;
  border: 1px solid;
  text-align: left;
  margin-top: -5px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
} */

.checkoutProduct_image {
  object-fit: contain;
  width: 180px;
  height: 150px;
}

.checkoutProduct_rating {
  padding: 0;
  margin: 0;
  display: flex;
  margin-top: -10px;
}

.checkoutProduct_title {
  font-size: 17px;
  text-align: left;
  font-weight: 300;
}

.checkoutProduct_price {
  padding: 0;
  margin: 10px 0;
  font-weight: 600;
  /* margin-top: -10px; */
}

.checkoutProduct_button > button {
  padding: 10px 10px;
  margin: 25px 5px 0;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.checkoutProduct_button > button:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}

.checkoutProduct_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
