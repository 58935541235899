.order {
  padding: 10px 10px;
  margin: 20px 0;
  background-color: rgb(236, 236, 236);
  position: relative;
}

.order_heading {
  background-color: rgb(223, 223, 223);
  padding: 10px;
  display: flex;
  height: 50px;
  justify-content: space-between;
}

.order_number {
  display: flex;
  align-items: center;
}

.order_number > h3 {
  font-weight: 300;
}

.order_option {
  margin-top: -20px;
  /* display: flex; */
}

.order_option > h3,
.order_total > h3 {
  font-weight: 300;
  margin-bottom: -10px;
}

.order_option > p,
.order_total > p,
.order_details {
  font-weight: 300;
  font-size: 15px;
  margin-bottom: -10px;
  color: rgb(105, 105, 105);
}

.order_id {
  font-size: 13px;
  color: rgb(105, 105, 105);

  padding-left: 5px;
  font-weight: 300;
}

.order_total,
.order_number {
  font-weight: 500;
  text-align: left;
}

.order_details {
  margin-top: -15px;
  text-align: right;
  transition: color 0.3s;
  color: rgb(105, 105, 105);
  /* cursor: pointer; */
}
/* 
.order_details:hover {
  color: rgb(0, 0, 0);
} */
