.corporate {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px;
    padding: 0px;
}

.corporate_container {
    max-width: 600px;
}

.corporate_optionParaOne {}

.corporate_optionLineOne {
    margin: 15px 0 15px;
    font-size: 30px;
    text-transform: uppercase;
}

.corporate_optionLineTwo {
    font-size: 15px;
    text-transform: uppercase;
    margin: 15px 0 15px;
}

.corporate_optionParaOne {
    margin: 25px 0 15px;
}

.corporate_optionParaTwo {
    margin: 25px 0 15px;
}

.corporate_optionLineThree {
    margin: 15px 0 15px;
}

.corporate_link>button {
    padding: 10px 30px;
    margin: 10px 20px;
    border: 1px solid rgba(87, 87, 87, 0.37);
    background-color: rgba(255, 255, 255, 0.068);
    text-transform: uppercase;
    font-size: 11px;
    color: rgb(126, 126, 126);
    transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.corporate_link>button:hover {
    color: rgb(39, 39, 39);
    cursor: pointer;
    border: 1px solid rgb(102, 102, 102);
    background-color: rgba(235, 235, 235, 0.404);
}