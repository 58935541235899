.payment {
  margin: 10px 60px;
}

.payment_container>h1 {
  text-align: center;
  width: 100%;
  font-weight: 300;
  padding-bottom: 30px;
  background-color: rgb(234, 237, 237);
  border-bottom: 1px solid lightgray;
  text-decoration: none;
  color: black;
}

.payment_link {
  text-decoration: none;
  color: rgb(92, 92, 92);
}

.payment_title {
  width: 300px;
  margin-left: 20px;
  text-align: left;
  height: 100%;
}

.payment_title>h3 {
  font-weight: +600;
}

.payment_section {
  display: flex;
  font-weight: 300;
  padding: 0px 20px 25px;
  /* align-items: baseline; */
  margin: 0px 0px 10px;
  border-bottom: 1px solid lightgray;
}

.payment_section_method {
  display: flex;
  font-weight: 300;
  padding: 0px 20px 15px;
  /* align-items: baseline; */
  margin: 0px 0px 10px;
}

.payment_address {
  display: flex;
  font-weight: 300;
  flex-direction: column;
  align-items: flex-start;
}

.payment_address_lines {
  margin: 0;
}

.payment_items {
  margin-left: 20px;
}

.payment_details>form>div>button {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}


.payment_details>form>div>button {
  width: 100%;
  padding: 10px 10px;
  margin-top: 10px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.payment_details>form>div>button:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}

.payment_address, .payment_items {
  width: 100%;
}

.payment_details {
  width: 100%;
  margin-top: 15px;
}

.subtotal_total {
  font-weight: 600;
}

.payment_error{
  margin-top: 15px;
}