.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 50px 50px;
  padding: 0px;
  height: 600px;
}

.contact_optionLineOne {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 50px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(114, 114, 114);
  transition: color 0.3s, background-color 0.3s;
}

.contact_line {
  display: flex;
  align-items: center;
  margin: 10px;
  border: 1px solid rgb(151, 151, 151);
}

.contact_line > p {
  width: 80px;
  margin: 0 20px;
  padding: 0 0 0 15px;
  font-size: 20px;
  text-align: left;
  color: rgb(110, 110, 110);
  font-weight: 300;
}

.contact_line > input {
  width: 300px;
  padding: 15px 20px;
  font-size: 16px;
  outline: none;
  color: rgb(46, 46, 46);
  background-color: rgb(220, 220, 220);
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
}

.contact_line > textarea {
  width: 300px;
  padding: 10px 20px;
  font-size: 16px;
  outline: none;
  color: rgb(46, 46, 46);
  background-color: rgb(220, 220, 220);
  border: none;
  resize: none;
}

.contact_submit {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #0069ed;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 300px;
  padding: 15px 20px;
  font-size: 20px;
  outline: none;
  color: rgb(105, 105, 105);
  background-color: rgb(220, 220, 220);
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
}

.contact_submit:hover {
  color: rgb(14, 14, 14);
  background-color: rgb(226, 226, 226);
}

.contact_option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact_sent {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 20px;
  padding: 10px;
  margin: 30px 50px;
  color: rgb(80, 80, 80);
}
