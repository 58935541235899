.home_image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.home_products {
  width: 100%;
  z-index: 1;
  transition: box-shadow 0.3s;
}

.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-bottom: 15px;
  margin-right: auto;
}

.home_products {
  margin: 15px 15px 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.home_categories {
  margin: 15px 15px 15px;
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
  text-decoration: underline;
  opacity: 0.8;
  color: black;
  cursor: pointer;
  transition: opacity 0.3s, color 0.3s;
}

.home_categories:hover {
  opacity: 1;
  color: black;
}

.home_line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_row {
  display: flex;
  z-index: 1;
  margin-right: 5px;
  margin-left: 5px;
}

.home_container {
  margin: 0 50px;
}
