.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login_logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 100px;
  margin-right: auto;
  margin-left: auto;
}

.login_container {
  width: 300px;
  margin-top: 30px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 10px 20px 20px;
}

.login_container > h1 {
  font-weight: 300;
  margin-top: 30px;
}

.login_container > form > h5 {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 300;
  text-align: left;
}

.login_container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login_signInButton {
  background-color: rgba(255, 255, 255, 0.068);
  border-radius: 2px;
  width: 100%;
  height: 35px;
  margin-top: 10px;
  border: 1px solid rgba(66, 66, 66, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(82, 82, 82);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.login_signInButton:hover,
.login_registerButton:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}

.login_registerButton {
  background-color: rgba(255, 255, 255, 0.068);
  border-radius: 2px;
  width: 100%;
  height: 35px;
  margin-top: 10px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(53, 53, 53, 0.37, 068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(78, 78, 78);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.login_logo {
  margin-top: 50px;
  height: 30px;
  padding: 0px 0px 10px 0px;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  color: black;
}

.login_logoText {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 25px;
  padding: 0;
  font-weight: 600;
  font-size: 35px;
}

.login_logoDot {
  margin: 0px 3px;
  height: 0px;
}

.login_logoDot > p {
  width: 6px;
  height: 6px;
  background-color: red;
}

.login_input {
  height: 50px;
  padding: 10px;
  font-size: 15px;
  background-color: #f4f3f4;
  border: 1px solid gray;
  box-sizing: border-box;
  margin: 10px 0 10px;
  width: 100%;
}
