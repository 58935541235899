.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 300px;
  height: 135px;
  padding-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 10px;
  background-color: #ececec;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.subtotal_gift {
  display: flex;
  align-items: center;
  margin-top: -10px;
}

.subtotal_gift>input {
  margin-right: 5px;
}


.subtotal>button {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.subtotal>button:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}