.header {
  padding: 20px 50px 25px;
  margin: 10px 00px 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
}

.header_option {
  height: 20px;
  display: flex;
  align-items: center;
}

.header_mailLogo,
.header_languageLogo,
.header_accountLogo,
.header_dollarLogo,
.header_returnsLogo {
  width: 15px;
  height: 15px;
  padding: 0 15px 0;
  border-left: 1px solid lightgray;
}

.header_returnsLogo {
  opacity: 0.6;
}

.header_basketLogo {
  width: 11px;
  height: 11px;
  padding: 0 16px 0;
}

.header_basket {
  height: 15px;
  width: 85px;
  margin: 0 5px;
  border-left: 1px solid lightgray;
  display: flex;
  align-items: center;
}

.header_mail,
.header_language,
.header_account,
.header_returns {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 5px 0;
}

.header_number,
.header_languageText,
.header_accountText,
.header_returnsText {
  margin: 0 5px 0 0;
  display: flex;
  color: #646464;
  transition: color 0.3s;
  align-items: center;
}

.header_returnsText:hover {
  color: black;
}

.header_link {
  text-decoration: none;
  color: rgb(126, 126, 126);
  transition: color 0.3s;
  cursor: pointer;
}

.header_link:hover {
  color: black;
}
