.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 60px 70px;
  padding: 0px;
}
.about_container {
  max-width: 800px;
}

.about_option {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 50px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(114, 114, 114);
  transition: color 0.3s, background-color 0.3s;
}

.about_optionLineOne {
  margin: 5px;
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
}
