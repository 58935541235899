.warranty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 50px 50px;
  padding: 0px;
}

.warranty_container {
  max-width: 1000px;
}
.warranty_optionLineOne {
  margin: 25px 0 25px;
  font-size: 30px;
  text-transform: uppercase;
}

.warranty_plans {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.warranty_plans > p {
  cursor: pointer;
  min-width: 380px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(114, 114, 114);
  background-color: rgb(223, 223, 223);
  transition: color 0.3s, background-color 0.3s;
}

.warranty_title {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 50px;
  padding: 10px;
  margin: 10px 10px 30px;
  color: rgb(114, 114, 114);
  transition: color 0.3s, background-color 0.3s;
}

.warranty_plans > p:hover {
  color: black;
  background-color: rgb(230, 230, 230);
}

.warranty_file {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.warranty_option {
  width: 380px;
  cursor: pointer;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 18px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(114, 114, 114);
  background-color: rgb(250, 250, 250);
  transition: color 0.3s, background-color 0.3s;
}

.warranty_option > p {
  width: 235px;
  height: 100px;
  font-size: 16px;
  margin: 15px;
  display: flex;
  align-items: center;
  text-align: left;
  color: rgb(114, 114, 114);
  transition: color 0.3s;
}

.warranty_logo {
  width: 35px;
  height: 35px;
  margin: 15px;
  padding: 10px;
  background-color: rgb(184, 184, 184);
}

.warranty_option:hover {
  background-color: rgb(238, 238, 238);
}

.warranty_option > p:hover {
  color: black;
}
