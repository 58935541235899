.pcparts_image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.pcparts_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}

.pcparts_page > p {
  padding: 15px 30px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.815);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.pcparts_page > p:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(255, 255, 255, 0.774);
}

.pcparts {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 20px;
  width: 100%;
  max-width: 1500px;
}

.pcparts_products_category {
  margin: 15px 15px 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
}

.pcparts_products {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.pcparts_categories {
  margin: 15px 15px 15px;
  font-size: 11px;
  text-align: left;
  text-transform: uppercase;
  text-decoration: underline;
  opacity: 0.8;
  color: black;
  cursor: pointer;
  transition: opacity 0.3s, color 0.3s;
}

.pcparts_categories:hover {
  opacity: 1;
  color: black;
}

.pcparts_line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pcparts_row {
  display: flex;
  z-index: 1;
  margin-right: 5px;
  margin-left: 5px;
}

.pcparts_container {
  width: 100%;
  margin: 0 50px;
}

.category_lds_container {
  height: 1000px;
  margin-top: 100px;
}
