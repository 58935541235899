.navigation {
  margin: 0 60px 15px;
  display: flex;
  justify-content: space-between;
}

.navigation_logo {
  height: 30px;
  padding: 0px 15px 0px 0px;
  display: flex;
  align-items: center;
  border-right: 1px solid lightgray;
}

.navigation_logoText {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 25px;
  padding: 0;
  font-weight: 600;
  font-size: larger;
}

.navigation_logoDot {
  margin: 0px 3px;
  height: 20px;
}

.navigation_logoDot>p {
  width: 5px;
  height: 5px;
  background-color: red;
}

.navigation_menu {
  margin: 5px 12px;
}

.navigation_menu>div {
  margin: 5px;
  width: 15px;
  height: 1px;
  background-color: gray;
}

.navigation_option {
  display: flex;
  align-items: center;
}

.navigation_category {
  margin: 10px 0;
  height: 30px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 30px;
  overflow: hidden;
  width: 100%;
  height: 30px;
}

.navigation_search {
  margin: 10px 0;
  padding: 3px;
  height: 25px;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  width: 150px;
}

input[type="text"] {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: black;
  font-weight: 100;
  font-size: 13px;
}

.navigation_searchIcon {
  margin: 5px;
  width: 15px;
  height: 15px;
}

.navigation_categoryText {
  flex: 1 1 auto;
  text-decoration: none;
  /* font-weight: 500; */
  font-size: 600;
  margin: 3px 5px;
  padding: 3px;
  border-radius: 10px;
  color: #636363;
  transition: color 0.3s, background-color 0.3s;
}

.navigation_categoryText:hover {
  text-decoration: none;
  cursor: pointer;
  color: black;
  background-color: #dfdfdf;
}

.navigation_categoryTextSelect {
  flex: 1 1 auto;
  text-decoration: none;
  font-size: 600;
  margin: 3px 5px;
  padding: 3px;
  border-radius: 10px;
  color: black;
  background-color: #dfdfdf;
  cursor: pointer;
  transition: color 0.3s, background-color 0.3s;
}

.navigation_link {
  text-decoration: none;
  color: black;
}