.footer {
  background-color: #262626;
  padding: 50px 65px 30px;
  display: flex;
  flex-direction: column;
}

.footer_categories {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 235px;
  justify-content: space-between;
}

.footer_category {
  display: flex;
  height: 235px;
  flex-direction: column;
  align-items: flex-start;
}

.footer_category > p {
  margin: 0 0 8px;
  font-size: 13px;
  text-transform: capitalize;
  color: gray;
  transition: color 0.3s;
}

.footer_category > p:hover {
  cursor: pointer;
  color: rgb(233, 233, 233);
}

.footer_categoryText {
  text-transform: capitalize;
  color: white;
  margin-bottom: 25px;
  font-weight: 500;
  cursor: pointer;
}

.footer_logo {
  height: 30px;
  padding: 0px 15px 0px 0px;
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

.footer_logoText {
  display: flex;
  color: white;
  align-items: center;
  height: 30px;
  margin-bottom: 25px;
  padding: 0;
  font-weight: 600;
  font-size: larger;
}

.footer_logoDot {
  margin: 0px 3px;
  height: 20px;
}

.footer_logoDot > p {
  width: 5px;
  height: 5px;
  background-color: red;
}

.footer_link {
  flex-wrap: wrap;
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
}

.footer_link > p {
  margin-right: 20px;
  text-decoration: underline;
  color: rgb(177, 177, 177);
  transition: color 0.3s;
}

.footer_link > p:hover {
  cursor: pointer;
  color: white;
}

.footer_border {
  margin: 20px 0 20px;
  border-bottom: 1px solid #505050;
}

.footer_copyright {
  color: gray;
  display: flex;
  font-size: 11px;
  justify-content: space-between;
}

.footer_terms {
  display: flex;
}

.footer_terms > div {
  margin-left: 20px;
  color: gray;
  transition: color 0.3s;
}

.footer_terms > div:hover {
  cursor: pointer;
  color: lightgray;
}
