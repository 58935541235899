.expressmessage {
  margin: -5px 0 20px;
  height: 50px;
  background-color: #262626;
  display: flex;
  align-items: center;
  justify-content: center;
}

.expressmessage_text {
  font-size: small;
  font-weight: 500;
  color: #a1a1a1;
}
