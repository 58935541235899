.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  padding: 20px;
  /* width: 100%; */
  min-width: 100px;
  /* max-width: 300px; */
  height: 450px;
  flex: 0 0 27%;
  background-color: white;
  z-index: 1;
  box-shadow: 0px 0px 10px rgb(228, 228, 228);
  transition: box-shadow 0.3s;
}

.product:hover {
  box-shadow: 0px 0px 10px #b1b1b1;
}

.product_rating {
  padding: 0;
  margin: 0;
  display: flex;
  margin-top: -15px;
}

.product > img {
  max-height: 200px;
  margin-bottom: 30px;
  margin-top: 15px;
  object-fit: contain;
  width: 100%;
}

.product_button {
  width: 100%;
}

.border {
  border-top: 1px solid lightgray;
  width: 100%;
  margin-top: -5px;
  margin-bottom: 10px;
}

.product_button > button {
  padding: 15px 30px;
  margin: 5px 20px;
  border: 1px solid rgba(87, 87, 87, 0.37);
  background-color: rgba(255, 255, 255, 0.068);
  text-transform: uppercase;
  font-size: 11px;
  color: rgb(126, 126, 126);
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.product_button > button:hover {
  color: rgb(39, 39, 39);
  cursor: pointer;
  border: 1px solid rgb(102, 102, 102);
  background-color: rgba(235, 235, 235, 0.404);
}

.product_price {
  padding: 0;
  margin: 0;
  margin-top: -10px;
}

.product_title {
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.product_info {
  width: 100%;
  padding-bottom: 15px;
  margin: 0;
  border-bottom: 1px solid lightgray;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -15px;
}

.product_link {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
