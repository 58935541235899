.jobs {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 50px 70px;
  padding: 0px;
}

.jobs_container {
  max-width: 1000px;
}

.jobs_option {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  font-size: 50px;
  padding: 10px;
  margin: 10px 10px 20px;
  color: rgb(114, 114, 114);
  transition: color 0.3s, background-color 0.3s;
}

.jobs_optionLineOne {
  margin: 5px;
  font-size: 20px;
  font-weight: 300;
}

.jobs_optionLineTwo {
  margin: 25px 0 25px;
  font-size: 30px;
  text-transform: uppercase;
}

.jobs_careers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 10px;
}

.jobs_careers > p {
  font-size: 18px;
  font-weight: 300;
  margin: 5px;
  text-align: center;
  padding: 20px;
}

.jobs_careers > div {
  font-size: 18px;
  font-weight: 300;
  margin: 5px;
  text-align: center;
  width: 260px;
  padding: 20px;
  text-transform: uppercase;
  background-color: rgb(236, 236, 236);
  color: rgb(146, 146, 146);
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
}
.jobs_careers > div:hover {
  background-color: rgb(225, 225, 225);
  color: black;
}
